<template>
  <PageContentLayoutOnly>
    <b-button rounded type="is-primary" @click="$refs.adderModal.open()"
      >Add Board Meeting</b-button
    >

    <ModernTable
      data-id="meetings table"
      :columns="columns"
      :rows="rows"
      :filters="{
        show: true
      }"
      :loading="loading"
    />

    <AdderModal ref="adderModal" :_close="close" />
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import AdderModal from '../AdderModal'
import { computed } from './keys/computed'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { watch } from './keys/watch'

export default {
  components: {
    PageContentLayoutOnly,
    AdderModal,
    ModernTable
  },

  computed,
  data,
  methods,
  watch,

  mounted() {
    this.refresh()
  }
}
</script>
