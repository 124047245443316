import { notifyError } from '@/services/notify'
import { listBoardPackages } from '@/services/BoardMeetings/BoardPackage/List'
import { eraseBoardMeeting } from '@/services/BoardMeetings/BoardPackage/Delete'

export const methods = {
  async refresh() {
    this.loading = true

    await this.getList()

    this.loading = false
  },

  close() {
    this.refresh()
  },

  async getList() {
    const { list, successful, message } = await listBoardPackages({
      params: {
        hoaID: this.hoaId
      }
    })
    if (!successful) {
      notifyError(message)
      return
    }

    this.list = list
  },

  async erase({ boardPackageID }) {
    this.loading = true

    await eraseBoardMeeting({
      boardPackageID
    })

    await this.refresh()

    this.loading = false
  },

  confirmDelete({ details }) {
    const { boardPackageID } = details

    this.$buefy.dialog.confirm({
      title: 'Deleting Board Meeting',
      message: `Are you sure you want to <b>delete</b> the board meeting #${boardPackageID}? This action cannot be undone.`,
      confirmText: 'Delete Board Meeting',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        this.erase({ boardPackageID })
      }
    })
  }
}
