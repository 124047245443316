export function data() {
  return {
    loading: false,
    //
    rows: [],
    columns: [
      {
        field: 'id',
        label: 'ID',
        numeric: true,
        sortable: true,
        searchable: true
      },
      {
        field: 'meetingDate',
        label: 'Meeting Date',
        date: true,
        sortable: true,
        searchable: true
      },
      {
        field: 'meetingType',
        label: 'Type',
        sortable: true,
        searchable: true
      },
      {
        field: 'financialMonth',
        label: 'Financial Month',
        sortable: true,
        searchable: true
      },
      {
        field: 'time',
        label: 'Time of Meeting',
        sortable: true,
        searchable: true
      },
      {
        field: 'open'
      },
      {
        field: 'edit'
      },
      {
        field: 'delete'
      }
    ],
    //
    list: [],
    toggle: false
  }
}
