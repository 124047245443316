import { parseDate } from '@/utilities/Date/parse'
import _get from 'lodash/get'
import SimpleButton from '@/components/buttons/Simple'
import BuefyButton from '@/components/buttons/Buefy'

export const watch = {
  hoaId() {
    this.refresh()
  },
  list(list) {
    const component = this

    this.rows = list.map(entry => {
      return {
        id: parseInt(_get(entry, 'boardPackageID', '')),

        meetingDate: parseDate(_get(entry, 'meetingDate', ''), 'LL'),
        meetingType: _get(entry, 'type', ''),
        financialMonth: parseDate(_get(entry, 'financialMonth', ''), 'MMMM YYYY'),
        time: parseDate(_get(entry, 'meetingDate', ''), 'h:mm a'),

        open: {
          component: SimpleButton,
          props: {
            to: {
              name: 'boardMeetingDetails',
              params: {
                id: _get(entry, 'boardPackageID', '')
              },
              query: {
                mode: 'open'
              }
            },
            text: 'Details'
          }
        },

        edit: {
          component: SimpleButton,
          props: {
            to: {
              name: 'boardMeetingDetails',
              params: {
                id: _get(entry, 'boardPackageID', '')
              },
              query: {
                mode: 'edit'
              }
            },
            text: 'Edit'
          }
        },

        erase: {
          component: BuefyButton,
          props: {
            click: function() {
              component.confirmDelete({ details: entry })
            },
            type: 'is-danger',
            text: 'Delete',
            isRounded: true
          }
        }
      }
    })
  }
}
