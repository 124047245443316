<template>
  <Modal :toggle.sync="toggle" :styles="{ modalContent: { minHeight: '100px' } }">
    <Detail ref="meetingDetail" :adderModal="true" :editMode="true" :close="close" />

    <template v-slot:header>Add Board Meeting</template>

    <template v-slot:footer>
      <b-button class="is-primary" @click="add" :style="{ marginLeft: '20px', float: 'right' }"
        >Add</b-button
      >
      <b-button class="is-primary" @click="toggle = false">Cancel</b-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'

import Detail from '@/pages/Management/boardMeetings/Panels/Detail'

export default {
  components: {
    Modal,
    Detail
  },
  data() {
    return {
      toggle: false,
      meetingTypes: ['Executive', 'Regular']
    }
  },
  props: {
    _close: Function
  },
  methods: {
    open() {
      this.toggle = true
    },
    close() {
      this.toggle = false
      this._close()
    },
    add() {
      const fields = this.$refs.meetingDetail.add()
      console.log({ fields })
    }
  }
}
</script>
